.gallery-icon {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 2px solid #c5ccd3;
  border-radius: 5px
}

.gallery-icon .gallery-icon__icon {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 3px
}

.gallery-icon .gallery-icon__icon svg {
  width: 100%;
  height: 100%
}

.gallery-icon .gallery-icon__text {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
  padding: 0 4px 8px;
  color: #7d8d9e;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word
}

.gallery-icon .gallery-icon__text .gallery-icon__text-label {
  width: 100%;
  min-width: 0
}

.gallery-icon--upload {
  border: 2px dashed #068eef;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.gallery-icon--upload .gallery-icon__icon {
  color: #068eef;
  transition: color 0.1s linear
}

.gallery-icon--upload .gallery-icon__icon svg {
  fill: currentColor
}

.gallery-icon--upload .gallery-icon__text {
  color: #068eef;
  transition: color 0.1s linear
}

.gallery-icon--upload:hover {
  border-color: #0463a7
}

.gallery-icon--upload:hover .gallery-icon__icon {
  color: #0463a7
}

.gallery-icon--upload:hover .gallery-icon__text {
  color: #0463a7
}

.gallery-image {
  display: flex;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 1px solid #c5ccd3
}

.gallery-image .gallery-image__img {
  flex-shrink: 0;
  max-width: 100%;
  max-height: 100%;
  image-orientation: from-image
}

.gallery-image--tall .gallery-image__img {
  max-height: none
}

.gallery-image--wide .gallery-image__img {
  max-width: none
}

.gallery {
  box-sizing: border-box;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.gallery .gallery__hidden-input {
  display: none
}

.gallery .gallery__items-inner {
  overflow-x: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  margin: 0 -16px -8px -16px;
  padding: 0 8px 0 16px;
  font-size: 0;
  white-space: nowrap;
  transition: height .1s ease-out;
  -webkit-overflow-scrolling: touch
}

.gallery .gallery__items-inner::-webkit-scrollbar {
  box-sizing: border-box;
  height: 5px
}

.gallery .gallery__items-inner::-webkit-scrollbar-thumb {
  box-sizing: border-box;
  height: 5px;
  border-radius: 2px;
  background: #7d8d9e;
  cursor: move
}

.gallery .gallery__items-inner::-webkit-scrollbar-button {
  display: block;
  box-sizing: border-box;
  width: 8px;
  height: 8px;
  background: transparent
}

@media screen and (min-width: 768px) {
  .gallery .gallery__items-inner {
    margin: 0 -24px -8px -24px;
    padding: 0 16px 0 24px
  }

  .gallery .gallery__items-inner::-webkit-scrollbar-button {
    width: 12px
  }
}

@media screen and (min-width: 1024px) {
  .gallery .gallery__items-inner {
    overflow: visible;
    margin: 0 -8px -8px 0;
    padding: 0;
    white-space: normal
  }
}

@media screen and (min-width: 1024px) {
  .gallery--hide-items .gallery__items-inner {
    overflow: hidden;
    white-space: nowrap
  }
}

.gallery .gallery__item {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 125px;
  min-width: 100px;
  max-width: 150px;
  margin: 0 8px 8px 0;
  font-size: 14px;
  vertical-align: top
}

.gallery .gallery__item::before {
  content: '';
  display: block;
  box-sizing: border-box;
  padding-top: 100%
}

.gallery .gallery__item-inner {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  transition: width .2s, height .2s, max-width .2s, min-width .2s
}

.gallery .gallery__item-placeholder {
  display: inline-block;
  margin: 0 10px;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: rgba(6, 142, 239, 0.5)
}

.gallery .gallery__item-img {
  display: block;
  position: relative;
  box-sizing: border-box;
  flex-basis: auto;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  image-orientation: from-image
}

.gallery .gallery__item-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  opacity: 0;
  -ms-grid-columns: 100%;
  -ms-grid-rows: 35px 1fr 35px;
  grid-template-columns: 100%;
  grid-template-rows: 35px 1fr 35px;
  align-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.45);
  color: #fff;
  text-align: center;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 12px;
  line-height: 18px
}

.gallery .gallery__item-overlay .gallery__item-overlay-text {
  display: block;
  box-sizing: border-box;
  -ms-grid-row: 2;
  grid-row: 2;
  width: 100%;
  padding: 0 2px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word
}

.gallery .gallery__item-link {
  -ms-grid-row: 3;
  grid-row: 3;
  width: 100%;
  padding: 0 4px;
  color: #daeefd;
  font-weight: 600;
  line-height: 1;
  white-space: normal;
  transition: color .1s linear;
  cursor: pointer;
  word-wrap: break-word;
  word-break: break-word
}

.gallery .gallery__item-link:hover {
  color: #aebeca
}

.gallery .gallery__item-link:active {
  color: #e1f1fd
}

.gallery .gallery__item-controls {
  display: flex;
  box-sizing: border-box;
  -ms-grid-row: 1;
  grid-row: 1;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%
}

.gallery .gallery__item-controls .gallery__item-control {
  box-sizing: border-box;
  width: 35px;
  height: 35px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 16px;
  cursor: pointer
}

.gallery .gallery__item-controls .gallery__item-control--remove {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23daeefd' d='M15.6 15.5c-.53.53-1.38.53-1.91 0L8.05 9.87 2.31 15.6c-.53.53-1.38.53-1.91 0s-.53-1.38 0-1.9l5.65-5.64L.4 2.4C-.13 1.87-.13 1.02.4.49s1.38-.53 1.91 0l5.64 5.63L13.69.39c.53-.53 1.38-.53 1.91 0s.53 1.38 0 1.91L9.94 7.94l5.66 5.65c.52.53.52 1.38 0 1.91z'%3E%3C/path%3E%3C/svg%3E")
}

.gallery .gallery__item-controls .gallery__item-control--remove:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%23aebeca' d='M15.6 15.5c-.53.53-1.38.53-1.91 0L8.05 9.87 2.31 15.6c-.53.53-1.38.53-1.91 0s-.53-1.38 0-1.9l5.65-5.64L.4 2.4C-.13 1.87-.13 1.02.4.49s1.38-.53 1.91 0l5.64 5.63L13.69.39c.53-.53 1.38-.53 1.91 0s.53 1.38 0 1.91L9.94 7.94l5.66 5.65c.52.53.52 1.38 0 1.91z'%3E%3C/path%3E%3C/svg%3E")
}

.gallery .gallery__item-controls .gallery__item-control--edit {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg fill='%23daeefd' fill-rule='evenodd'%3E%3Cpath d='M3.77 11.124L9.69 5.21l-.983-.985-5.92 5.915.86.193.123.79z'/%3E%3Cpath d='M10.34 5.846l-5.893 5.888-2.386.7-.494-.495.73-2.49 5.827-5.82-.707-.707L1.5 8.79c-.08.063-.146.145-.178.25l-1.24 4.227c-.055.184-.003.383.132.52.1.1.235.152.37.152.05 0 .1-.008.15-.022l4.226-1.24c.1-.03.177-.093.24-.166l5.93-5.876-.79-.79zM7.954 2.334l3.712 3.712.85-.796-3.712-3.713-.85.797zM13.492 2.28L11.712.502C11.223.01 10.43.006 9.945.492l-.546.553 3.557 3.558.547-.553c.486-.486.48-1.278-.01-1.77z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 17px 17px
}

.gallery .gallery__item-controls .gallery__item-control--edit:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='14' viewBox='0 0 14 14'%3E%3Cg fill='%23aebeca' fill-rule='evenodd'%3E%3Cpath d='M3.77 11.124L9.69 5.21l-.983-.985-5.92 5.915.86.193.123.79z'/%3E%3Cpath d='M10.34 5.846l-5.893 5.888-2.386.7-.494-.495.73-2.49 5.827-5.82-.707-.707L1.5 8.79c-.08.063-.146.145-.178.25l-1.24 4.227c-.055.184-.003.383.132.52.1.1.235.152.37.152.05 0 .1-.008.15-.022l4.226-1.24c.1-.03.177-.093.24-.166l5.93-5.876-.79-.79zM7.954 2.334l3.712 3.712.85-.796-3.712-3.713-.85.797zM13.492 2.28L11.712.502C11.223.01 10.43.006 9.945.492l-.546.553 3.557 3.558.547-.553c.486-.486.48-1.278-.01-1.77z'/%3E%3C/g%3E%3C/svg%3E")
}

.gallery .gallery__item-controls .gallery__item-control--hidden {
  opacity: 0;
  pointer-events: none
}

.gallery .gallery__item-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%
}

.gallery .gallery__item--hovered .gallery__item-overlay,
.gallery .gallery__item--ghost .gallery__item-overlay,
.gallery .gallery__item:hover .gallery__item-overlay {
  opacity: 1;
  pointer-events: auto
}

.gallery .ecwid-supports-touch .gallery__item {
  -webkit-user-select: none;
  -webkit-touch-callout: none
}

.gallery .gallery__item--draggable {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.gallery .gallery__item--draggable .gallery__item-overlay {
  display: -ms-grid;
  display: grid
}

.gallery .gallery__item--hidden {
  display: none
}

.gallery .gallery__item--ghost {
  position: absolute;
  z-index: 550;
  animation: set-highlight-shadow .2s 1 linear;
  cursor: -webkit-grabbing;
  cursor: grabbing;
  box-shadow: 0 5px 20px rgba(6, 142, 239, 0.3)
}

.gallery .gallery__item--ghost .gallery__item-overlay {
  transition: none
}

.gallery .gallery__item--ghost.gallery__item--ghost-removing {
  box-shadow: none;
  animation: unset-highlight-shadow .2s 1 linear
}

.gallery .gallery__item--dragged {
  z-index: 500;
  border: 0 none;
  box-shadow: none;
  cursor: -webkit-grabbing;
  cursor: grabbing
}

.gallery .gallery__item--dragged .gallery__item-inner {
  opacity: 0;
  transition: none
}

.gallery .gallery__item--smooth {
  transition: width .1s, height .1s, box-shadow .2s
}

.gallery .gallery__item--smooth .gallery__item-overlay {
  transition: none
}

.gallery .gallery__item-loader {
  display: none;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  left: 1px
}

.gallery {
  .gallery__item--loading,
  .dz-preview:not(.dz-complete),
  .dz-processing:not(.dz-complete) {
    animation: fadein .3s linear;
    pointer-events: none;

    .gallery__item-loader {
      display: block;
    }

    .gallery__item-content {
      opacity: 0.7;
    }

    .gallery__item-mark,
    .gallery__item-overlay,
    .gallery__item-img {
      display: none
    }

  }
}

.gallery .gallery__item--deleting {
  opacity: 0;
  transition: opacity .2s
}

.gallery .gallery__item-mark {
  display: inline-block;
  position: absolute;
  top: 8px;
  right: 1px;
  box-sizing: border-box;
  max-width: 0;
  height: 2em;
  padding: 0;
  background: #068eef;
  box-shadow: -1px 1px 5px rgba(2, 50, 84, 0.3);
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 2em;
  text-align: center;
  white-space: nowrap;
  transition: max-width .2s .2s, padding .2s .2s
}

.gallery .gallery__item-mark>span {
  display: inline-block;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
  max-width: 100%
}

.gallery .gallery__item-mark--left {
  right: auto;
  left: 1px;
  box-shadow: 1px 1px 5px rgba(2, 50, 84, 0.3)
}

.gallery .gallery__item--marked .gallery__item-mark {
  max-width: 90%;
  padding: 0 8px
}

.gallery .gallery__item-removed-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  -ms-grid-columns: 100%;
  -ms-grid-rows: 1fr 35px;
  grid-template-columns: 100%;
  grid-template-rows: 1fr 35px;
  align-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 35px;
  background: rgba(190, 198, 207, 0.5);
  font-weight: 600;
  text-align: center;
  cursor: default;
  font-size: 12px;
  line-height: 18px
}

.gallery .gallery__item-removed-overlay .gallery__item-removed-text {
  -ms-grid-row: 1;
  grid-row: 1;
  color: #1f2328;
  line-height: 1;
  white-space: normal;
  word-wrap: break-word;
  word-break: break-word
}

.gallery .gallery__item-removed-overlay .gallery__item-undo {
  -ms-grid-row: 2;
  grid-row: 2;
  font-weight: 600;
  white-space: normal;
  cursor: pointer;
  transition: color 0.1s linear;
  word-wrap: break-word;
  word-break: break-word
}

.gallery .gallery__item-removed-overlay .gallery__item-undo .iconable-link {
  padding: 0;
  text-align: center;
  word-break: break-all;
  white-space: normal
}

.gallery .gallery__item-removed-overlay .gallery__item-undo .iconable-link .iconable-link__icon {
  position: static;
  padding: 0 3px 0 0
}

.gallery .gallery__item--removed {
  pointer-events: none
}

.gallery .gallery__item--removed .gallery__item-removed-overlay {
  display: -ms-grid;
  display: grid
}

.gallery .gallery__item--removed .gallery__item-removed-overlay .iconable-link {
  pointer-events: auto
}

.gallery .gallery__item--removed .gallery__item-overlay {
  display: none
}

.gallery .gallery__item--removed .gallery__item-content {
  opacity: .4
}

.dnd-overlay-holder {
  position: relative
}

.dnd-overlay {
  display: none;
  visibility: hidden;
  position: absolute
}

.dnd-overlay--full-page {
  position: fixed
}

.dnd-overlay--active {
  display: flex;
  visibility: visible;
  z-index: 99999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(6, 142, 239, 0.85)
}

.dnd-overlay .dnd-overlay__inner {
  display: flex;
  position: relative;
  z-index: 100;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 330px;
  text-align: center
}

.dnd-overlay .dnd-overlay__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-bottom: 8px
}

.dnd-overlay .dnd-overlay__icon svg {
  width: 100%;
  height: 100%;
  color: #fff
}

.dnd-overlay .dnd-overlay__text {
  display: block;
  color: #fff;
  font-size: 20px;
  line-height: 28px;
  font-family: var(--headers-font-family);
  font-weight: var(--headers-font-weight, 700)
}

@media screen and (min-width: 768px) {
  .dnd-overlay .dnd-overlay__text {
    font-size: 32px;
    line-height: 38px
  }
}

.dnd-overlay .dnd-overlay__note {
  display: none;
  color: #fff;
  font-size: 14px;
  line-height: 22px
}

.dnd-overlay--has-note .dnd-overlay__note {
  display: block
}

.dnd-overlay--has-note .dnd-overlay__text {
  margin-bottom: 8px
}